import Apiservice from '@/core/services/api.service';
import { API_URL } from '@/common/config.js'
const apiService = Apiservice;


export default class GradePointValueService {

      #api = null;

      constructor() {
            this.#api = `${API_URL}user/grade-point`;
      }

      paginate(id, data = {}, index = 1) {
            let url = `${this.#api}/${id}/value`;
            if (index != null)
                  url = `${url}?page=${index}`;
            let param = {
                  params: data
            }
            return apiService.query(url, param);
      }

      createAndUpdate(pointId, data) {
            let url = `${this.#api}/${pointId}/value`
            return apiService.post(url, data);
      }

      delete(pointId, id) {
            let url = `${this.#api}/${pointId}/value/${id}`
            return apiService.delete(url);
      }
}
