<template>
  <v-dialog v-model="dialog" max-width="1300" persistent>
      <v-card>

        <v-toolbar dark>
          <v-card-title class="headline">
            {{`${edit?'Update':"Add"} grade point`}}
            <hr>
          </v-card-title>

          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-spacer></v-spacer>
            <v-btn icon  @click="hideModal">
              <i class="fas fa-times"></i>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <div class="row">
              <div class="col-4">
                <v-text-field outlined dense v-model="point.title" label="Title"></v-text-field>
                <span class="text-danger" v-if="$v.point.title.$error">This information is required</span>
              </div>
              <div class="col-4">
                <v-select :items="types" item-text="title" item-value="value" outlined dense
                          v-model="point.type"
                          label="Result type"></v-select>
              </div>
              <div class="col-4">
                <v-select :items="grade_point_types" item-text="title" item-value="value" outlined dense
                          v-model="point.grade_point_type"
                          label="Grade point type"></v-select>
              </div>
              <div class="col-3" v-if="point.grade_point_type == 'course'">
                Has different course grading point
                <v-switch @change="changePointValue" v-model="point.has_different_course_grade_point" :label="point.has_different_course_grade_point?'Yes':'No'">
                </v-switch>
              </div>
              <div class="col-3">
                Is Default
                <v-switch v-model="point.is_default" :label="point.is_default?'Yes':'No'">
                </v-switch>
              </div>
              <div class="col-3">
                Status
                <v-switch v-model="point.is_active"  :label="point.is_active?'Active':'Inactive'">
                </v-switch>
              </div>
            </div>
            <template  v-if="!point.has_different_course_grade_point">
              <div class="row">
                <div class="col-12">
                  <h4>Grading calculation value & logic</h4>
                  <v-btn @click="addValue" class="mt-4 btn btn-primary float-right" style="color: #fff">
                    <i class="fas fa-plus"></i> Add new condition
                  </v-btn>
                </div>
              </div>
              <div class="row mt-3" v-for="(value, index) of values" :key="index">
                <div class="col-2">
                  <v-text-field outlined dense v-model="value.start_from" label="Mark lower limit"></v-text-field>
                  <span class="text-danger"
                        v-if="$v.values.$each[index].start_from.$error">This information is required</span>
                </div>
                <div class="col-2">
                  <v-text-field outlined dense v-model="value.end_to" label="Mark upper limit"></v-text-field>
                  <span class="text-danger"
                        v-if="$v.values.$each[index].end_to.$error">This information is required</span>
                </div>
                <div class="col-2" v-if="point.type == 'gpa'">
                  <v-text-field outlined dense v-model="value.grade" label="Grade"></v-text-field>
                  <span class="text-danger"
                        v-if="point.type == 'gpa' && $v.values.$each[index].grade.$error">This information is required</span>
                </div>
                <div class="col-2" v-if="point.type == 'gpa'">
                  <v-text-field outlined dense v-model="value.grade_point" label="Grade point"></v-text-field>
                  <span class="text-danger"
                        v-if="point.type == 'gpa' && $v.values.$each[index].grade_point.$error">This information is required</span>
                </div>
                <div class="col-2">
                  <v-text-field outlined dense v-model="value.division" label="Division"></v-text-field>
                  <span class="text-danger"
                        v-if="$v.values.$each[index].division.$error">This information is required</span>
                </div>
                <div class="col-1" v-if="point.grade_point_type=='course'">
                  <v-switch v-model="value.is_pass" :label="value.is_pass==1?'Pass':'Fail'">
                  </v-switch>
                </div>
                <div class="col-1">
                  <v-btn type="button" @click="removeValue(index, value.id)" fab small color="pink">
                    <v-icon color="white" small>
                      mdi-trash-can
                    </v-icon>
                  </v-btn>

                </div>
                <hr/>
              </div>

            </template>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              class="btn btn-standard cancel-btn"
              depressed
              @click="hideModal">
            Cancel
          </v-btn>
          <v-btn v-if="checkIsAccessible('grade-point', 'create')"
                 class="text-white ml-2 btn btn-primary"
                 depressed
                 @click="createAndUpdate"
                 :loading="isBusy"
          >Save
          </v-btn>
        </v-card-actions>
      </v-card>

    </v-dialog>
</template>
<script>
import {required} from "vuelidate/lib/validators";

import GradePointService from "@/core/services/level/program/grade-point/GradePointService";
import GradePointValueService from "@/core/services/level/program/grade-point/value/GradePointValueService";

const pointService = new GradePointService();
const valueService = new GradePointValueService();

export default {
  props: ["program_id",],
  name: "grade-point-create-or-update",
  validations() {
    return {
      point: {
        title: {required},
        type: {required}
      },
      values: this.rule
    }
  },

  data() {
    return {
      edit: false,
      isBusy: false,
      dialog: false,
      types: [
        {title: "Percentage", value: 'percentage'},
        {title: "GPA", value: 'gpa'}
      ],
      grade_point_types: [
        {title: "Overall", value: 'overall'},
        {title: "Course", value: 'course'}
      ],
      point: {
        title: "Default",
        type: "percentage",
        is_default: true,
        has_different_course_grade_point: false,
        is_active: true,
      },
      values: [{
        grade_point_id: null,
        start_from: null,
        end_to: null,
        grade: null,
        grade_point: null,
        division: null,
        is_pass:1,
        remarks: null,
      }]
    };
  },
  computed: {
    rule() {
      let rule = {}
      if(!this.point.has_different_course_grade_point) {
        if (this.point.type == 'gpa') {
          rule = {
            $each: {
              start_from: {required},
              end_to: {required},
              grade: {required},
              grade_point: {required},
              division: {required},
            }
          }
        } else {
          rule = {
            $each: {
              start_from: {required},
              end_to: {required},
              division: {required},
            }
          }
        }
      }
      return rule;

    }
  },
  methods: {
    changePointValue() {
        if (this.point.has_different_course_grade_point) {
          this.values = [];
        } else {
          this.values = [{
            grade_point_id: null,
            start_from: null,
            end_to: null,
            grade: null,
            grade_point: null,
            division: null,
            is_pass:1,
            remarks: null,
          }]
        }
    },

    showModal(gradePoint = null) {
      if (gradePoint) {
        this.edit = true;
        this.point = gradePoint
        this.values = gradePoint.values;
      }else{
        this.edit=false;
        this.resetGradePoint();
      }
      this.dialog = true;
    },
    hideModal() {
      this.dialog = false;
      this.edit=false;
      this.resetGradePoint()
    },

    createAndUpdate() {
      this.$v.$touch();
      if (this.$v.$error) {
        setTimeout(() => {
          this.$v.$reset();
        }, 3000);
      } else {
        this.point.values = this.values
        if (this.edit) {
          this.updateGradePoint();
        } else {
          this.createGradePoint();
        }
      }
    },
    createGradePoint() {
      pointService.store(this.point).then(response => {
        this.resetGradePoint()
        this.$snotify.success("Grade point added");
        this.dialog = false;
        this.$emit("refresh");
      });
    },
    updateGradePoint() {
      pointService.update(this.point.id, this.point).then(response => {
        this.resetGradePoint()
        this.$snotify.success("Grade point updated");
        this.dialog = false;
        this.$emit("refresh");
      });
    },
    resetGradePoint() {
      this.$v.$reset();
      this.point = {
        title: "Default",
        type: "percentage",
        is_default: true,
        is_active: true,
        has_different_course_grade_point: false,
      }
      this.values = [{
        grade_point_id: null,
        start_from: null,
        end_to: null,
        grade: null,
        grade_point: null,
        is_pass: 1,
        division: null,
        remarks: null,
      }]

    },
    removeValue(index, id = null) {
      this.$confirm({
        message: `Are you sure you want to delete this item?`,
        button: {
          no: "No",
          yes: "Yes"
        },
        /**
         * Callback Function
         * @param {Boolean} confirm
         */
        callback: confirm => {
          if (confirm) {
            if (id) {
              valueService
                  .delete(this.point.id, id)
                  .then(response => {
                    this.$snotify.success("Information deleted");
                  })
                  .catch(error => {
                    //console.log(error);
                  });
            }
            this.values.splice(index, 1);

          }
        }
      });

    }, addValue() {
      this.values.push({
        grade_point_id: null,
        start_from: null,
        end_to: null,
        grade: null,
        grade_point: null,
        is_pass: 1,
        division: null,
        remarks: null,
      });
    },
  }
};
</script>
