<template>
  <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>Exam grade point</h4>
                <div class="breadcrumb-sub-header">
                  <router-link to="/dashboard">Dashboard</router-link>
                  \ Grade point
                </div>
              </div>
              <div
                class="breadcrumb-right"
                v-if="checkIsAccessible('grade-point', 'create')"
              >
                <div class="card-toolbar">
                  <v-btn
                    @click="openDialog()"
                    class="mt-4 btn btn-primary"
                    style="color: #fff"
                  >
                    <v-icon small elevation="2" outlined>fas fa-plus</v-icon>&nbsp; Add
                    grade point
                  </v-btn>
                </div>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="table-responsive">
              <v-skeleton-loader v-if="loading" type="table-thead" > </v-skeleton-loader>

              <v-skeleton-loader v-if="loading" type="table-row-divider@25">
              </v-skeleton-loader>
              <table class="table" v-if="!loading">
                <thead>
                  <tr>
                    <th class="px-3 wrap-column">Title</th>
                    <th class="px-3 wrap-column">Type</th>
                    <th class="px-3 wrap-column">Grade point type</th>
                    <th class="px-3 wrap-column">Default</th>
                    <th class="px-3 wrap-column">Active</th>
                    <th class="pr-3 text-center">Action</th>
                  </tr>
                </thead>
                <tbody v-if="points">
                  <tr v-for="(point, i) of points" :key="i">
                    <td class="px-3 wrap-column">{{ point.title }}</td>
                    <td class="px-3 wrap-column">{{ point.type_text }}</td>
                    <td class="px-3 wrap-column">{{ point.grade_point_type_text }}</td>
                    <td class="px-3 pt-3 wrap-column">
                      <span
                        class="badge"
                        :class="point.is_default ? 'badge-success' : 'badge-danger'"
                        >{{ point.is_default ? "DEFAULT" : "NO" }}</span
                      >
                    </td>
                    <td class="px-3 pt-3 wrap-column">
                      <span
                        class="badge"
                        :class="point.is_active ? 'badge-success' : 'badge-danger'"
                        >{{ point.is_active ? "Active" : "Inactive" }}</span
                      >
                    </td>
                    <!--                      <td>
                          <span class="cursor-pointer ml-3" @click="editDialog(point.id)" v-if="checkIsAccessible('grade-point', 'edit')">
                            <i class="fas fa-edit"></i>
                          </span>
                        <span class="cursor-pointer ml-3" @click="deleteGradepoint(point.id)" v-if="checkIsAccessible('grade-point', 'delete')">
                            <i class="fas fa-trash"></i>
                          </span>
                      </td>-->
                    <td class="pr-0 text-center">
                      <template>
                        <b-dropdown
                          size="sm"
                          variant="link"
                          toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                          no-caret
                          right
                          no-flip
                        >
                          <template v-slot:button-content>
                            <i class="ki ki-bold-more-hor"></i>
                          </template>
                          <!--begin::Navigation-->
                          <div class="navi navi-hover min-w-md-250px">
                            <b-dropdown-text
                              tag="div"
                              class="navi-item"
                              v-if="checkIsAccessible('grade-point', 'edit') && point.has_different_course_grade_point"
                            >
                              <a class="navi-link" @click="manageDifferentCourse(point.id)">
                                <span class="navi-icon">
                                  <i class="fas fa-bars"></i>
                                </span>
                                <span class="navi-text">Manage different course</span>
                              </a>
                            </b-dropdown-text>

                            <b-dropdown-text
                              tag="div"
                              class="navi-item"
                              v-if="checkIsAccessible('grade-point', 'edit')"
                            >
                              <a class="navi-link" @click="editDialog(point)">
                                <span class="navi-icon">
                                  <i class="fas fa-edit"></i>
                                </span>
                                <span class="navi-text">Edit</span>
                              </a>
                            </b-dropdown-text>

                            <b-dropdown-text
                              tag="div"
                              class="navi-item"
                              v-if="checkIsAccessible('grade-point', 'delete')"
                            >
                              <a
                                class="navi-link"
                                @click.prevent="deleteGradePoint(point.id)"
                              >
                                <span class="navi-icon">
                                  <i class="fas fa-trash"></i>
                                </span>
                                <span class="navi-text">Delete</span>
                              </a>
                            </b-dropdown-text>
                          </div>
                          <!--end::Navigation-->
                        </b-dropdown>
                      </template>
                    </td>
                  </tr>
                </tbody>
                <tbody v-else>
                  <tr>
                    <td colspan="10">
                      No data available to display.
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <create-and-update
            ref="points"
            @close_dialog="closeDialog"
            @refresh="getGradePoints"
          ></create-and-update>

        </div>
      </div>
    </div>
  </v-app>
</template>
<script>
import CreateAndUpdate from "./CreateOrUpdate";
import GradePointService from "@/core/services/level/program/grade-point/GradePointService";

const pointService = new GradePointService();

export default {
  name: "Department",
  props: ["program_id"],
  components: {
    CreateAndUpdate,
  },
  data() {
    return {
      points: [],
      grade_id: null,
      page: null,
      total: null,
      perPage: null,
      loading: false,
    };
  },
  mounted() {
    this.getGradePoints();
  },
  methods: {
    getGradePoints() {
      this.loading = true;
      pointService.paginate().then((response) => {
        this.points = response.data.data;
        this.loading = false;
      }).catch(() => {
        this.loading = false;
      });
    },
    openDialog() {
      this.$refs["points"].showModal();
    },
    editDialog(id) {
      this.grade_id = id;
      this.$refs["points"].showModal(id);
    },
    closeDialog(id) {
      this.grade_id = id;
      this.$refs["points"].hideModal();
      this.getGradePoints();
    },
    manageDifferentCourse(pointId) {
      this.$tabs.open({
        name: 'grade-point-different-course',
        params: {id: pointId}
      })
    },
    deleteGradePoint(id) {
      this.$confirm({
        message: `Are you sure you want to delete this item?`,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            pointService.delete(id).then((response) => {
              this.$snotify.success("Information deleted");
              this.getGradePoints();
            });
          }
        },
      });
    },
  },
};
</script>
